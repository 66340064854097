import GooglePlaceAutoComplete from '../../services/GooglePlaceAutoComplete';

function searchUseLocation(checked) {
    let useLocationElement = document.querySelector("#use-location");
    let latitudeElement = document.querySelector("#latitude");
    let longitudeElement = document.querySelector("#longitude");
    let whereElement = document.querySelector("#where");
    let sortElement = document.querySelector("#sort");
    let oldSortValue = sortElement.value;

    if (checked) {
        whereElement.disabled = true;
        whereElement.placeholder = whereElement.dataset.placeholderLocation;
        navigator.geolocation.getCurrentPosition(success, error);
    } else {
        latitudeElement.value = "";
        longitudeElement.value = "";
        whereElement.disabled = false;
        whereElement.placeholder = whereElement.dataset.placeholderWhere;
        sortElement.value = oldSortValue;
    }

    function success(position) {
        let whereElement = document.querySelector("#where");
        whereElement.value = "";

        sortElement.value = "Closest";
        let decimalPlaces = 3; // Within 111 m of the user. Preserve the users privacy by not using precise location.
        let latitude = position.coords.latitude.toFixed(decimalPlaces);
        let longitude = position.coords.longitude.toFixed(decimalPlaces);

        latitudeElement.value = latitude;
        longitudeElement.value = longitude;
    }

    function error() {
        useLocationElement.checked = false;
        useLocationElement.disabled = true;
        whereElement.placeholder = whereElement.dataset.placeholderWhere;

        disableWhereInputIfUseLocation();
    }

}

function disableWhereInputIfUseLocation() {
    let whereElement = document.querySelector("#where");
    let useLocationElement = document.querySelector("#use-location");

    if (useLocationElement.checked) {
        whereElement.disabled = true;
        whereElement.placeholder = whereElement.dataset.placeholderLocation;
    } else {
        whereElement.disabled = false;
        whereElement.placeholder = "";
    }
}

function onChangeLatitudeAndLongitude (place) {
    let latitudeElement = document.querySelector("#latitude");
    let longitudeElement = document.querySelector("#longitude");

    latitudeElement.value = place.place.geometry.location.lat();
    longitudeElement.value = place.place.geometry.location.lng();
}

(function () {
    let useLocationElement = document.querySelector("#use-location");
    var placeInput = document.querySelector('.google-place-autocomplete');

    new GooglePlaceAutoComplete(placeInput, onChangeLatitudeAndLongitude);

    if (useLocationElement) {
        if (!navigator.geolocation) {
            useLocationElement.checked = false;
            useLocationElement.disabled = true;
        } else {
            useLocationElement.addEventListener("change",
                function () {
                    searchUseLocation(this.checked);
                });
        }
    }

    disableWhereInputIfUseLocation();

})();
