class GooglePlaceAutoComplete {
    constructor(streetAddressInput, onPlaceChanged) {
        if (window.google) {
            this._streetAddressInput = streetAddressInput;
            this._onPlaceChanged = onPlaceChanged;
            this.InitGooglePlaceAutocomplete();
            this._autocomplete.addListener('place_changed', this.OnPlaceChanged.bind(this));
            this._streetAddressInput.addEventListener('keydown', function (event) {
                if (event.keyCode === 13) {
                    event.preventDefault();
                }
            });
        }
    }

    _streetAddressInput;
    _onPlaceChanged;
    _autocomplete;

    InitGooglePlaceAutocomplete() {
        this._autocomplete = new google.maps.places.Autocomplete(this._streetAddressInput, {
            //Give results in Sweden, Norway, Finland, Denmark and Åland Islands
            componentRestrictions: { country: ['SE', 'NO', 'FI', 'DK', 'AX'] }
        });
    }

    GetPlace() {
        return this._autocomplete.getPlace();
    }

    OnPlaceChanged() {
        this._onPlaceChanged({
                place: this.GetPlace()
            });
    }
}

export default GooglePlaceAutoComplete;
